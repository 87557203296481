/* eslint-disable no-jquery/no-jquery-constructor, no-jquery/no-find-collection, no-jquery/no-other-methods, no-jquery/no-attr, no-jquery/no-html */

import React from 'react';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { Observer } from 'mobx-react-lite';
import MuiTheme from '@/shared/components/MuiTheme';
import theme from '@/shared/components/theme';
import { createEmbeddedComponentStore, EmbeddedComponentStore, EmbeddedComponentStoreFactory, RenderComponentFunction } from './embeddedComponentStoreFactory';

const render: RenderComponentFunction = (ReactComponentClass, data, contentHtml, id, logging, useMuiTheme) => {
  return <Observer>
    {() => {
      const passTheme = useMuiTheme ? theme : undefined;
      const contents = <ReactComponentClass
        {...toJS(data)}
        theme={passTheme}
        assignProperties={(changes: object) => {
          runInAction(() => {
            logging && console.log(`setting "${id}" to ${JSON.stringify(changes)}`);
            Object.assign(data, changes);
          });
        }}>
        {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} />}
      </ReactComponentClass>;

      return useMuiTheme ? <MuiTheme>{contents}</MuiTheme> : contents;
    }}
  </Observer>;
};

const init = (store: EmbeddedComponentStore) => {
  makeAutoObservable(store);
};

export const embeddedComponentStore = createEmbeddedComponentStore(
  render,
  init,
);

embeddedComponentStore.init();
